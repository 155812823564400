import React from 'react';

import { ScrollProvider } from './scroll';

const AppProvider: React.FC = ({ children }) => (
  <ScrollProvider>
    {children}
  </ScrollProvider>
);

export default AppProvider;
