import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

import { Container } from './styles';

const FloatButton: React.FC = () => {
  return (
    <Container
      href="https://wa.me/5511981630707"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaWhatsapp size={26} color="#FFF" />
    </Container>
  );
};

export default FloatButton;
