import React from 'react';
import Testimony from '../../../../components/Testimony';

import { Container, Wrapper } from './styles';

interface TestimoniesProps {
  id: string;
}

const Testimonies: React.FC<TestimoniesProps> = ({ id }) => {
  return (
    <Container id={id}>
      <strong>Depoimentos</strong>
      <Wrapper>
        <Testimony
          quote="Muito feliz por ter escolhido a Anna Beatriz como fonoaudióloga. Ela é super dedicada, simpática, atenciosa e muito talentosa. Eu trabalho com a voz no dia-a-dia e eu fiquei apavorada quando descobri que estava com uma fenda nas cordas vocais. Corri para fazer uma avaliação com ela, e ela logo me acalmou e me mostrou que era necessário se livrar de velhos hábitos e de como a dedicação do paciente também é necessária para a melhora. Fiquei muito feliz quando descobri que ela fechou! Antes eu não entendia muito bem como a fonoaudiologia era importante, mas agora percebo que ela foi essencial na minha vida, tanto para o trabalho como para a minha auto estima. Obrigada mil vezes!!"
          author="Carolina Liz"
        />
        <Testimony
          quote="Ter a Anna Beatriz como minha fono foi um dos grandes presentes que tive!
          Uma pessoa extremamente profissional e dedicada.
          Ela está sempre pronta para tirar minhas dúvidas e trazer exercícios novos para para curar as lesões que tive em minhas cordas vocais.
          Hoje me sinto mais segura para continuar fazendo o que mais amo: cantar e contar históras, pois sinto que estou tendo cada vez mais uma melhor performance no meu trabalho.
          Super indico essa fono maravilhosa! "
          author="Regina Cazorla, ReBrincando - Arte diversão"
        />
        <Testimony
          quote="Antes de conhecer a Anna Beatriz, eu perdia minha voz de maneira cotidiana. Eu vivo intensamente, saio, canto, grito, bebo, e acima de tudo toco trompete ha mais de 10 anos. A vida carnavalesca me pegou, e levou a minha voz.
          Quando comecei a ser atendida pela  Anna Beatriz descobri que não sabia respirar (que absurdo para um ser humano!) e Senti pela primeira vez meu diafragma (que absurdo para uma trompetista!). A minha vida mudou. Hoje continuo carnavalesca, mas eu não perco minha voz e se exagerar eu sempre lembro da Anna Beatriz e faço meus exercícios. Ela me acompanhou com carinho e alegria, sempre buscando me envolver de uma maneira interativa e dinâmica."
          author="Zélie"
        />
        <Testimony
          quote="Enquanto professor de piano online e presencial, preciso falar muito e cantar trechos para explicar o resultado que o aluno deve alcançar.
          No fim de cada aula eu me encontrava exausto e rouco, precisando forçar e falar com dificuldade.
          Busquei ajuda e a Anna logo notou que eu tinha problemas na respiração e utilização de minha voz falada.
          Com a aplicação dos exercícios e me conscientizando dessa nova forma de falar encontrei mais segurança em minhas aulas, gosto mais do som da minha voz e a rouquidão passou.
          Com a técnica que aprendi, posso dar longas horas de aula sem me cansar e tampouco ficar rouco.
          Gratidão pelo excelente trabalho e cuidado Anna!"
          author="Eder Giaretta"
        />
        <Testimony
          quote="5 estrelas! Profissional extremamente capacitada, dedicada ao aluno/paciente, minuciosa na avaliação e cuidadosa na fonoterapia, atenciosa e paciente.
          Aborda não só a questão técnica, mas a subjetividade do aluno/paciente. Recomento a todos que procuram um serviço de excelência em fonoaudiologia e canto."
          author="Vinícius Magalhães"
        />
      </Wrapper>
    </Container>
  );
};

export default Testimonies;
