import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  margin-top: 6.4rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    width: 55rem;
    font-size: 1.6rem;
    line-height: 2.6rem;
    text-align: center;
  }

  button {
    margin-top: 8rem;
    width: 28rem;
    height: 6.4rem;
  }

  @media (max-width: 1200px) {
    margin-top: 1.6rem;
  }

  @media (max-width: 626px) {
    p {
      width: 100%;
    }
  }

  @media (max-width: 450px) {
    padding-top: 0;

    button {
      margin-top: 4rem;
    }
  }
`;

export const Avatar = styled.img`
  width: 23.2rem;
  height: 23.2rem;
  border-radius: 50%;
  border: 2px solid var(--primary);
  box-sizing: border-box;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  object-fit: cover;
  display: none;
  margin-bottom: 4.8rem;
  object-position: top;

  @media (max-width: 1025px) {
    display: block;
  }

  @media (max-width: 450px) {
    width: 16.5rem;
    height: 16.5rem;
  }
`;
