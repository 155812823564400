import styled, { css } from 'styled-components';

interface ContainerProps {
  isActive: boolean;
}

export const Container = styled.div<ContainerProps>`
  list-style: none;
  margin-left: 2.5rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-items: center;

  @media (max-width: 824px) {
    margin-left: 0;
  }

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding-bottom: 4px;

    &.scrolled {
      border-bottom-color: #000;
      transition: border-bottom-color 100ms linear;
    }

    &:first-child {
      margin-left: 0;
    }

    span {
      color: #000;
      text-decoration: none;
      font-size: 2.4rem;
      font-weight: 400;
      transition: font-weight 200ms linear;

      ${(props) =>
        props.isActive
          ? css`
              font-weight: 700;
            `
          : css``}

      &.scrolled {
        color: #000;
        transition: color 200ms linear;
      }
    }

    @media (max-width: 824px) {
      padding: 1.5rem 0;
      padding-bottom: 2px;

      &:first-child {
        margin-left: 0;
      }

      span {
        font-size: 2.4rem;
      }

      span + span {
        margin: 2rem 0 0;
      }
    }

    @media (max-width: 1200px) {
      span {
        font-size: 1.4rem;
      }
    }
  }
`;

export const ItemIcon = styled.a`
  margin-left: 16px;

  svg {
    color: var(--secondary);
  }

  @media (max-width: 824px) {
    margin-left: 16px;
  }
`;
