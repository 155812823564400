import React from 'react';
import AtuationItem from '../../../../components/AtuationItem';

import { Container, Wrapper } from './styles';

import vozImg from '../../../../assets/voz.svg';
import falaImg from '../../../../assets/fala.svg';
import motricidadeImg from '../../../../assets/motricidade.svg';

interface AtuationProps {
  id: string;
}

const Atuation: React.FC<AtuationProps> = ({ id }) => {
  return (
    <Container id={id}>
      <strong>Atuação</strong>
      <Wrapper>
        <AtuationItem name="Fala" image={falaImg} />
        <AtuationItem name="Voz" image={vozImg} />
        <AtuationItem name="Motricidade orofacial" image={motricidadeImg} />
      </Wrapper>
    </Container>
  );
};

export default Atuation;
