import styled from 'styled-components';

export const Container = styled.a`
  display: none;
  height: 5.6rem;
  width: 5.6rem;
  background: var(--primary);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  position: fixed;
  right: 1.6rem;
  bottom: 5.6rem;
  z-index: 15;

  @media (max-width: 626px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
