import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  padding-top: 8rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  strong {
    font-weight: 700;
    font-size: 3.6rem;
    margin-bottom: 4rem;
  }

  span {
    font-size: 2.4rem;
    margin-bottom: 4rem;
  }

  @media (max-width: 450px) {
    padding-top: 4rem;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    margin-left: 1.6rem;
    font-size: 1.6rem;
    color: var(--secondary);
    text-decoration: none;
    font-weight: 700;
  }

  & + div {
    margin-top: 2.4rem;
  }
`;
