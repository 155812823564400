import styled from 'styled-components';

import semiLogo from '../../assets/semi-logo.svg';
import avatarImg from '../../assets/avatar.jpg';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  background-image: url(${semiLogo});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 55% 150px;

  @media (max-width: 626px) {
    background-position: 55% 220px;
  }

  @media (max-width: 530px) {
    background-position: 55% 190px;
  }

  @media (max-width: 450px) {
    background-position: 55% 165px;
  }

  @media (max-width: 360px) {
    background-position: 55% 140px;
  }
`;

export const Header = styled.header`
  position: fixed;
  /* padding-left: 58rem; */
  padding-left: 74rem;
  width: 100%;

  background-image: url(${semiLogo});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 55% 150px;
  background-color: var(--background);

  nav {
    width: 100%;
    max-width: 80rem;
    margin: 0 auto;
  }

  @media (max-width: 1430px) {
    padding-left: 62rem;

    nav {
      max-width: 60rem;
    }
  }

  @media (max-width: 1300px) {
    padding-left: 50rem;
  }

  @media (max-width: 1100px) {
    padding-left: 42rem;
  }

  @media (max-width: 1025px) {
    padding-left: 0;
  }

  @media (max-width: 626px) {
    background-image: none;
  }
`;

export const Logo = styled.div`
  width: 100%;
  max-width: 80rem;
  margin: 0 auto;
  padding: 2.4rem 3.2rem;

  > img {
    width: 100%;
  }

  @media (max-width: 626px) {
    padding: 2.4rem 3.2rem 0;
  }
`;

export const Aside = styled.aside`
  overflow: hidden;
  position: fixed;
  z-index: 50;
`;

export const Mask = styled.div`
  position: fixed;
  height: 184.5rem;
  width: 102rem;
  left: -28rem;
  /* left: clamp(-42rem, -18vw, -28rem); */
  /* left: max(-18vw, -42rem); */
  top: -12rem;
  border-radius: 50%;
  border: 5px solid var(--primary);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  overflow: hidden;

  div {
    flex: 1;
    max-height: 100vh;
    margin-top: 115px;

    background-image: url(${avatarImg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 150px 15%;
  }

  @media (max-width: 1430px) {
    left: -40rem;
  }

  @media (max-width: 1300px) {
    left: -52rem;

    div {
      background-position: 230px 15%;
    }
  }

  @media (max-width: 1100px) {
    left: -60rem;
  }

  @media (max-width: 1025px) {
    display: none;
  }
`;

export const Main = styled.main`
  margin-left: 74rem;
  padding: 0 3.2rem;
  padding-top: 35rem;

  @media (max-width: 1430px) {
    margin-left: 62rem;
  }

  @media (max-width: 1300px) {
    margin-left: 50rem;
  }

  @media (max-width: 1100px) {
    margin-left: 42rem;
  }

  @media (max-width: 1025px) {
    margin-left: 0;
  }

  @media (max-width: 742px) {
    padding-top: 30rem;
  }

  @media (max-width: 626px) {
    padding-top: 20rem;
  }

  @media (max-width: 450px) {
    padding-top: 18.5rem;
  }

  @media (max-width: 365px) {
    padding-top: 16rem;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 80rem;
  margin: 0 auto;

  padding: 3.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 530px) {
    padding: 0;
  }
`;
