import React from 'react';

import { Container, Quote, Author } from './styles';

interface TestimonyProps {
  quote: string;
  author: string;
}

const Testimony: React.FC<TestimonyProps> = ({ quote, author }) => {
  return (
    <Container>
      <Quote>{quote}</Quote>
      <Author>- {author}</Author>
    </Container>
  );
};

export default Testimony;
