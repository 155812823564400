import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  padding-top: 8rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  strong {
    font-weight: 700;
    font-size: 3.6rem;
    margin-bottom: 4rem;
  }

  @media (max-width: 450px) {
    padding-top: 4rem;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1.6rem;

  div + div {
    margin-top: 3.2rem;
  }

  @media (max-width: 450px) {
    padding: 0;

    div + div {
      margin-top: 1.6rem;
    }
  }
`;
