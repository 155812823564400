import React from 'react';

import { Container, Text } from './styles';

import nbbulaLogo from '../../assets/nbbula.svg';

const Footer: React.FC = () => {
  return (
    <Container>
      <Text>© 2024, Vocalizarte - Todos os direitos reservados</Text>
      <Text>
        Desenvolvido por
        <a href="https://nbbula.com" target="_blank" rel="noopener noreferrer">
          <img src={nbbulaLogo} alt="nbbula." />
        </a>
      </Text>
    </Container>
  );
};

export default Footer;
