import styled from 'styled-components';

export const Container = styled.div`
  background: rgba(35, 89, 55, 0.24);
  border-radius: 12px;
  border-left: 12px solid var(--primary);
  padding: 2.4rem;
  width: 100%;
`;

export const Quote = styled.p`
  color: var(--secondary);
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding-bottom: 2.4rem;
`;

export const Author = styled.span`
  color: var(--primary);
  font-size: 1.6rem;
`;
