import React from 'react';
import { FaRegEnvelope, FaWhatsapp, FaInstagram } from 'react-icons/fa';

import { Container, Wrapper, Item } from './styles';

interface ContactProps {
  id: string;
}

const Contact: React.FC<ContactProps> = ({ id }) => {
  return (
    <Container id={id}>
      <strong>Contato</strong>
      <span>Agende sua consulta!</span>
      <Wrapper>
        <Item>
          <FaRegEnvelope size={21} color="#000" />
          <a href="mailto:contato@vocalizarte.com.br">
            contato@vocalizarte.com.br
          </a>
        </Item>
        <Item>
          <FaWhatsapp size={21} color="#000" />
          <a
            href="https://wa.me/5511999358005"
            target="_blank"
            rel="noopener noreferrer"
          >
            +55 (11) 99935-8005
          </a>
        </Item>
        <Item>
          <FaInstagram size={21} color="#000" />
          <a
            href="https://instagram.com/vocaliz.arte"
            target="_blank"
            rel="noopener noreferrer"
          >
            @vocaliz.arte
          </a>
        </Item>
      </Wrapper>
    </Container>
  );
};

export default Contact;
