import styled from 'styled-components';

export const Container = styled.footer`
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 8rem;
  padding-bottom: 4rem;

  @media (max-width: 450px) {
    padding-top: 4rem;
  }
`;

export const Text = styled.p`
  color: #161614;
  font-size: 1.6rem;
  padding: 1rem;
  text-align: center;

  & + p {
    margin-top: 1.6rem;
  }

  a {
    margin-left: 1rem;
  }

  img {
    height: 1.6rem;
  }
`;
