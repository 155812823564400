import React from 'react';
import { Link, ScrollLinkProps } from 'react-scroll';
import { IconBaseProps } from 'react-icons';

import { Container, ItemIcon } from './styles';

interface ItemProps {
  title: string;
  activeItem: string | null;
  classes: string;
  icon?: React.ComponentType<IconBaseProps>;
  iconUrl?: string;
}

const Item: React.FC<ScrollLinkProps<ItemProps>> = ({
  title,
  activeItem,
  classes,
  icon: Icon,
  iconUrl,
  ...rest
}) => {
  return (
    <Container isActive={activeItem === rest.to}>
      <Link {...rest} spy smooth className={classes}>
        <span className={classes}>{title}</span>
      </Link>

      {Icon && iconUrl && (
        <ItemIcon href={iconUrl} target="_blank" rel="noopener noreferrer">
          <Icon size={28} />
        </ItemIcon>
      )}
    </Container>
  );
};

export default Item;
