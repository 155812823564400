import React from 'react';

import { Container, Avatar } from './styles';

interface AtuationItemProps {
  name: string;
  image: string;
}

const AtuationItem: React.FC<AtuationItemProps> = ({ name, image }) => {
  return (
    <Container>
      <Avatar src={image} alt={name} />
      <p>{name}</p>
    </Container>
  );
};

export default AtuationItem;
