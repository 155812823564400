import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import NavBar from '../../components/NavBar';
import FloatButton from '../../components/FloatButton';

import Formation from './Sections/Formation';
import Atuation from './Sections/Atuation';
import Testimonies from './Sections/Testimonies';
import Contact from './Sections/Contact';
import Footer from '../../components/Footer';

import { Container, Header, Logo, Aside, Mask, Main, Wrapper } from './styles';

import logoImg from '../../assets/logo.svg';

const Home: React.FC = () => {
  const sections = [
    {
      title: 'Formação',
      to: 'formation',
      offset: -450,
      duration: 500,
      component: Formation,
    },
    {
      title: 'Atuação',
      to: 'atuation',
      offset: -350,
      duration: 500,
      component: Atuation,
    },
    {
      title: 'Depoimentos',
      to: 'testimonies',
      offset: -350,
      duration: 500,
      component: Testimonies,
    },
    {
      title: 'Contato',
      to: 'contact',
      offset: -350,
      duration: 500,
      component: Contact,
      icon: FaWhatsapp,
      iconUrl: 'https://wa.me/5511999358005',
    },
  ];

  return (
    <Container>
      <Header>
        <NavBar sections={sections} />
        <Logo>
          <img src={logoImg} alt="Vocalizarte" />
        </Logo>
      </Header>
      <Aside>
        <Mask>
          <div />
        </Mask>
      </Aside>
      <Main>
        <FloatButton />
        <Wrapper>
          {sections.map((section) => (
            <section.component key={section.to} id={section.to} />
          ))}

          <Footer />
        </Wrapper>
      </Main>
    </Container>
  );
};

export default Home;
