import styled from 'styled-components';

export const Container = styled.div`
  width: 11.6rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-top: 1.6rem;
    font-size: 1.6rem;
    line-height: 2.6rem;
    text-align: center;
  }
`;

export const Avatar = styled.img`
  width: 11.6rem;
  height: 11.6rem;
  border-radius: 50%;
  border: 2px solid var(--primary);
  box-sizing: border-box;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  object-fit: cover;

  @media (max-width: 450px) {
    width: 7.4rem;
    height: 7.4rem;
  }
`;
