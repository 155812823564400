import styled from 'styled-components';

export const Container = styled.nav`
  height: 9rem;
  width: 100%;
  z-index: 10;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background-color: transparent;
  transition: background-color 200ms linear;

  span {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &.scrolled {
    background: var(--background);
    transition: background-color 200ms linear;
  }

  @media (max-width: 1200px) {
    height: 60px;
  }

  @media (max-width: 626px) {
    display: none;
  }
`;

export const NavLinks = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 64rem;
  opacity: 1;

  transition: opacity 200ms;

  @media (max-width: 1200px) {
    max-width: 53.5rem;
  }
`;
