import React, { useState, useCallback } from 'react';
import { IconBaseProps } from 'react-icons';
import { useScroll } from '../../hooks/scroll';
import Item from './Item';

import { Container, NavLinks } from './styles';

// import menuImg from '../../assets/menu.svg';

interface NavBarProps {
  sections: {
    title: string;
    to: string;
    offset: number;
    duration: number;
    icon?: React.ComponentType<IconBaseProps>;
    iconUrl?: string;
  }[];
}

const NavBar: React.FC<NavBarProps> = ({ sections }) => {
  const [activeItem, setActiveItem] = useState<string | null>(null);
  const { getScrollPosition } = useScroll();

  const handleSetActive = useCallback((section: string) => {
    setActiveItem(section);
  }, []);

  const handleSetInactive = useCallback(
    (section: string) => {
      if (activeItem === section || section === sections[0].to) {
        setActiveItem(null);
      }
    },
    [activeItem, sections],
  );

  return (
    <Container className={getScrollPosition() !== 0 ? 'scrolled' : ''}>
      <NavLinks>
        {sections.map((item) => (
          <Item
            classes={`${getScrollPosition() !== 0 ? 'scrolled' : ''}`}
            key={item.to}
            activeItem={activeItem}
            onSetActive={() => handleSetActive(item.to)}
            onSetInactive={() => handleSetInactive(item.to)}
            icon={item.icon}
            iconUrl={item.iconUrl}
            {...item}
          />
        ))}
      </NavLinks>
    </Container>
  );
};

export default NavBar;
