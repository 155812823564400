import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react';

interface ScrollContextData {
  getScrollPosition(): number;
}

const ScrollContext = createContext<ScrollContextData>({} as ScrollContextData);

const ScrollProvider: React.FC = ({ children }) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const listenToScroll = useCallback(() => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;

    setScrollPosition(scrolled);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
  }, [listenToScroll]);

  const getScrollPosition = useCallback(() => scrollPosition, [scrollPosition]);

  return (
    <ScrollContext.Provider value={{ getScrollPosition }}>
      {children}
    </ScrollContext.Provider>
  );
};

function useScroll(): ScrollContextData {
  const context = useContext(ScrollContext);

  if (!context) {
    throw new Error('useScroll must be used within a ScrollProvider');
  }

  return context;
}

export { ScrollProvider, useScroll };
