import React from 'react';
import Button from '../../../../components/Button';

import { Container, Avatar } from './styles';

import avatarImg from '../../../../assets/avatar.jpg';

interface FormationProps {
  id: string;
}

const Formation: React.FC<FormationProps> = ({ id }) => {
  const handleOpenCurriculum = () => {
    window.open('http://lattes.cnpq.br/8760380152327240', '_blank');
  };

  return (
    <Container id={id}>
      <Avatar src={avatarImg} alt="Anna Beatriz Gomes" />
      <p>
        Anna Beatriz é fonoaudióloga graduada pela Universidade Federal de São
        Paulo (UNIFESP), especialista em voz pela Escola de Educação Permanente
        do Hospital das Clínicas da FMUSP e com formação em Eletroestimulação e
        Fotobiomodulação aplicada à Fonoaudiologia pelo ENSI.
      </p>
      <br />
      <p>
        No campo das artes, Anna é mestre em ópera pela Faculdade de Artes de
        Berna, na Suíça. Iniciou seus estudos de canto lírico na Academia de
        Ópera do Theatro São Pedro e trabalhou como solista em São Paulo. Além
        disso, tem formação técnica em artes cênicas pela Escola de Teatro Célia
        Helena e dublagem pela Universidade de Dublagem.
      </p>
      <br />
      <p>
        Atualmente, Anna Beatriz Gomes está cursando doutorado em Ciências da
        Reabilitação na Universidade de São Paulo. Atua profissionalmente como
        fonoaudióloga e também como cantora na Europa, unindo ambas as suas
        paixões por meio do trabalho com voz profissional.
      </p>
      <Button onClick={handleOpenCurriculum}>Acessar currículo lattes</Button>
    </Container>
  );
};

export default Formation;
